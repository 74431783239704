
























































































































































































































import { Empresa } from '@/core/models/geral';
import { OrdemServico, OrdemServicoResiduo } from '@/core/models/residuo';
import { EnumSituacaoOrdemServico } from '@/core/models/residuo/Enumerados';
import { EmpresaService, VeiculoService, MotoristaService } from '@/core/services/geral';
import { OrdemDestinacaoService, OrdemServicoService } from '@/core/services/residuo';
import { ArquivoService } from '@/core/services/shared';
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class DetalharOrdemServico extends Vue {

empresa: Empresa = new Empresa();
item: OrdemServico = new OrdemServico();
service: OrdemServicoService = new OrdemServicoService();
ordemDestinacaoService: OrdemDestinacaoService = new OrdemDestinacaoService();
arquivoService = new ArquivoService();

overlay: boolean = false;

mounted(){

   const id: number = Number(this.$route.params.id);
   this.overlay = true;
   this.service.ObterPorId(id, 'Contrato.Cliente.Contato, Contrato.Cliente.Endereco.Municipio.Estado, Base, Porto, Embarcacao, Motorista, Veiculo, Situacao, Transportadora, Residuos.OrdemDestinacao.Arquivo, Residuos.Unidade, Residuos.Residuo, Residuos.Situacao, Residuos.Arquivo, Servicos.Servico, Locacoes.Tipo, Locacoes.Equipamento.Grupo, Locacoes.EquipamentoTroca.Grupo, Fotos')
   .then(
      res => this.item = res.data,
      err => this.$swal('Aviso', err.message, 'error')
   ).finally(()=>{
      const empresaService = new EmpresaService();
      empresaService.ObterPorId(this.item.empresaId)
         .then(
            res =>  this.empresa = res.data,
            err => this.$swal('Aviso', err.message, 'error')
      );
      this.overlay = false;
   });    
   }   

  Download(item: OrdemServicoResiduo, tipo: string) {

      this.service.Download(item.ordemServicoId, item.id, tipo).then(
      async (res) => {

         if (res.status == 204){
            return;
         }

         switch (tipo) {
            case 'FCDR':
               await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.fcdr!.nome);
               break;
            case 'MR01':
               await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr01!.nome);
               break;
            case 'CDF01':
               await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf01!.nome);
               break;
            case 'CartaResponsabilidade':
               await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cartaResponsabilidade!.nome);
               break;
            case 'TicketPesagem':
               await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.ticketPesagem!.nome);
               break;
            case 'RelatorioObservacao':
               await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioObservacao!.nome);
               break;
            case 'MR02':
               await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr02!.nome);
               break;
            case 'CDF02':
               await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf02!.nome);
               break;
            case 'RelatorioRecebimento2':
               await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioRecebimento2!.nome);
               break;
            default:
               console.warn(`Não existe esse tipo: ${tipo}.`);
         }

      },
         (err) => this.$swal("Aviso", err.message, "error")
      );
   }

   DownloadODArquivo(item: OrdemServicoResiduo, tipo: string) {
    this.ordemDestinacaoService.Download(item.ordemDestinacao.id, item.ordemServicoId, tipo).then(
      async (res) => {

        if (res.status == 204){
          return;
        }

        switch (tipo) {
          case 'MR02':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.mr02!.nome);
            break;
          case 'CDF02':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.cdf02!.nome);
            break;
          case 'RelatorioRecebimento2':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.relatorioRecebimento2!.nome);
            break;
          case 'MR03':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.mr03!.nome);
            break;
          case 'CDF03':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.cdf03!.nome);
            break;
          case 'RelatorioRecebimento3':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.relatorioRecebimento3!.nome);
            break;
          case 'MR04':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.mr04!.nome);
            break;
          case 'CDF04':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.cdf04!.nome);
            break;
          case 'RelatorioRecebimento4':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.ordemDestinacao.arquivo.relatorioRecebimento4!.nome);
            break;
          default:
            console.warn(`Não existe esse tipo: ${tipo}.`);
        }

      },
      (err) => this.$swal("Aviso", err.message, "error")
    );
  }

   TextoMarcaDagua(){
      return this.item.situacaoId == EnumSituacaoOrdemServico.Cancelado ? 'Cancelado' : '';
   }
}

